import React from "react";
import { Routes, Route } from "react-router-dom";
import DashboardApp from "./DashboardApp";
import Tearsheet from "./scenes/tearsheet";
import DailyReturnsProvider from "./context/DailyReturnsProvider";
import { NextUIProvider } from "@nextui-org/react";
import { Toaster, toast } from 'sonner'
import useScreenSize from "./hooks/useScreenSize";

function App() {
  
  const {isMobile} = useScreenSize()

  return ( 
    <>
     {isMobile && <div className="bg-black text-white w-full h-full text-center flex items-center justify-center">
       <p>Mobile version is still being worked on, please view on Desktop</p>
      </div>}
      {!isMobile &&  <NextUIProvider>
      <DailyReturnsProvider>
      <Toaster 
      position="top-right" 
      richColors
      />
      <Routes>
        <Route path="/tearsheet" element={<Tearsheet />} />
        <Route path="/*" element={<DashboardApp />} />
      </Routes>
    </DailyReturnsProvider>
    </NextUIProvider>}
    </> 
  );
}

export default App;
