import { useContext, useState } from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
// import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import GridViewIcon from '@mui/icons-material/GridView';
import { PeopleAltOutlined, Person, PersonOutline } from "@mui/icons-material";
import DailyReturnsContext from "../../context/DailyReturnsContext";
import {FaBars, FaTimes} from 'react-icons/fa'


const Sidebar = () => {
  const theme = useTheme();
  const location = useLocation()
  const {setShowSidebar} = useContext(DailyReturnsContext)
  return (
    <Box
    className={`py-4 w-[250px] h-screen z-[10] top-0 left-0 fixed bg-[#F6F5FA] border-r`}
     
    >
      <div className="w-[250px] flex flex-col justify-start items-center px-4">
        <div className="w-full pb-4 border-b border-[#D1D5DB] flex items-center justify-between">
        <p className='text-[#393939] font-bold text-[1rem] logo'>TAOSHI</p>
        <FaTimes
        onClick={()=>setShowSidebar(false)}
        className="md:hidden"
        color="black"
        />
        </div>

        <div className="pt-[40px] flex flex-col items-start justify-start w-full">
          <Link 
          to='/'
          className={`${location.pathname === '/'?'bg-[#393939] text-[white]':''} w-full flex items-center hover:text-[white] text-[#393939] cursor-pointer hover:bg-[#393939] p-2 rounded-lg`}>
            <GridViewIcon
            className=""
            /><Link 
            className='text-[1rem] ml-[10px] font-bold'
            to='/'>Overview</Link>
          </Link>
          <Link to='/orders' className={`${location.pathname === '/orders'?'bg-[#393939] text-[white]':''} w-full flex items-center hover:text-[white] cursor-pointer text-[#393939] my-4 hover:bg-[#393939] p-2 rounded-lg`}>
            <PeopleAltOutlined
            className=""
            /><Link 
            className='text-[1rem] ml-[10px] font-bold'
            to='/orders'>Orders</Link>
          </Link>
          <Link 
         
          to='/minerperformance' className={`${location.pathname === '/minerperformance'?'bg-[#393939] text-[white]':''} w-full flex items-center cursor-pointer hover:text-[white] text-[#393939] hover:bg-[#393939] p-2 rounded-lg`}>
            <PersonOutline
            className=""
            /><Link 
            className='text-[1rem] ml-[10px] font-bold'
            to='/minerperformance'>Miner Performance</Link>
          </Link>
        </div>
        
      </div>
    </Box>
  );
};

export default Sidebar;

