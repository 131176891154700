import axios from "axios";
import moment from "moment";

export const fetchDashboardData = async () => {
  try {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const resp = await axios.get(apiBaseUrl + "/data");

    const data = resp?.data?.payload;

    if (!data) {
      return {
        error: "An error occurred while fetching dashboard data",
        success: false,
      };
    }

    return { data, success: true };
  } catch (error) {
    return { error, success: false };
  }
};

export const fetchChartData = async (type) => {
  try {
    // Get the current date using Moment.js
    const today = moment();

    // Subtract one year from the current date
    const fromDate = today.subtract(32000, "minutes"); // 24 days approx

    // Format the date in 'YYYYMMDD' format
    const formattedDate = fromDate.format("YYYYMMDDTHHmmss");

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const resp = await axios.get(apiBaseUrl + "/chart-data", {
      params: {
        type,
        date: formattedDate,
      },
    });

    const data = resp?.data?.payload;

    if (!data) {
      return {
        error: "An error occurred while fetching chart data",
        success: false,
      };
    }

    const stockData = data.result.data.map(
      ({ datetime, open, close, high, low }) => ({
        x: new Date(datetime).getTime(),
        open,
        close,
        high,
        low,
      })
    );

    return { data: stockData.reverse(), success: true };
  } catch (error) {
    return { error, success: false };
  }
};

export const fetchPrice = async (date) => {
  try {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const resp = await axios.get(apiBaseUrl + "/price", {
      params: {
        date,
      },
    });

    const data = resp?.data?.payload;

    if (!data) {
      return {
        error: "An error occurred while fetching chart data",
        success: false,
      };
    }

    return { data, success: true };
  } catch (error) {
    return { error, success: false };
  }
};

export const getTearsheet = async (dailyReturns) => {
  try {
    // const apiBaseUrl =  'https://tearsheet-fastapi.onrender.com/api/v1'; 
    const apiBaseUrl =  'https://dmbppvk3cp.ap-south-1.awsapprunner.com/api/v1';

    const resp = await axios.post(apiBaseUrl + "/tearsheet", {
      daily_returns_data: dailyReturns,
    });

    const data = resp?.data;

    if (!data) {
      return {
        error: "An error occurred while fetching chart data",
        success: false,
      };
    }

    return { data, success: true };
  } catch (error) {
    return { error, success: false };
  }
};


export function truncateToFourDecimalPlaces(num) {
  const strNum = num.toString();
  const decimalIndex = strNum.indexOf('.');
  
  // If there is no decimal point, return the original number
  if (decimalIndex === -1) {
      return num;
  }

  // Extract the part before the decimal and the part after, up to 4 places
  const truncated = strNum.slice(0, decimalIndex + 5);
  
  // Convert the truncated string back to a number
  return parseFloat(truncated);
}
export function truncateToTwoDecimalPlaces(num) {
  const strNum = num.toString();
  const decimalIndex = strNum.indexOf('.');
  
  // If there is no decimal point, return the original number
  if (decimalIndex === -1) {
      return num;
  }

  // Extract the part before the decimal and the part after, up to 4 places
  const truncated = strNum.slice(0, decimalIndex + 3);
  
  // Convert the truncated string back to a number
  return parseFloat(truncated);
}
