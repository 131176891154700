import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useContext, useEffect, useState } from "react";
import TarvisDashboard from "./DashboardComponent";
import DashboardComponent from "./DashboardComponent";
import DailyReturnsProvider from "../../context/DailyReturnsProvider";
import DailyReturnsContext from "../../context/DailyReturnsContext";
import { IoMdRefresh } from "react-icons/io";
import { toast } from "sonner";
import UiTabs from "../tabs/Uitabs";
import { FaBars } from "react-icons/fa";

const Dashboard = ({ type, lineChartData }) => {

  const {getMinerData,setPositionsData,setShowSidebar,showSidebar} = useContext(DailyReturnsContext)


  return (
    <Box m="20px">
        <>
          {/* HEADER */}
          <Box
            display="flex"
            flexDirection='column'
            justifyContent="space-between"
            alignItems="center"
            borderBottom='1px'
          >
            <div className='w-full flex justify-between items-center pb-[4px]'>
            <p className='flex items-center text-[#393939] font-bold text-[1.3rem]'>
              <FaBars
              onClick={()=>setShowSidebar(true)}
              color="black"
              className="md:hidden"
              />
              &nbsp;
              Overview</p>
            <IoMdRefresh 
         onClick={()=>{
          toast.success('Refreshing')
          setPositionsData(null)
          getMinerData()
        }}
        color="#393939" 
        size={25}
        className="cursor-pointer"
        
        />
            </div>
          
          <div className="w-full bg-[#D1D5DB] h-[1px]"></div>
          </Box>

          <UiTabs
          items={['Tarvis Miner 1','Tarvis Miner 2','Others']}
          content={[<DashboardComponent type="TarvisTwo"
            />,<DashboardComponent type="Tarvis"
            />,<DashboardComponent
            type="Others"
            // statData={mockStatDataOthers}
            // pieChartData={mockPieDataOthers}
            // lineChartData={mockLineDataOthers}
          />]}
          />
          

          {/* GRID & CHARTS */}
         
        </>
    </Box>
  );
};

export default Dashboard;
