import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DailyReturnsContext from '../../context/DailyReturnsContext';
import Modal from '../modal';
import { jelly } from 'ldrs'

jelly.register()

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingTop: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function PerformanceTabs({setTabState,tabState,items,content,setDetailedOrdersTableData, detailedOrdersTableData,setDetailedOrdersLoading,getDetailedOrders,getEthDetailedOrders,getAllOrdersData,setAllOrders,setEthOrders }) {
  const {
    dailyReturnTableData,
    setDailyReturnTableData,
    addressSelected,
    slippagePercentage,
    tradeFeesPercentage,
    portfolioValueAssumption,
    multiplierAssumption,
    detailedOrdersTableDataSetter,
    calculating,setCalculating
  } = React.useContext(DailyReturnsContext);
  const [value, setValue] = React.useState(0);
  const [showModal,setShowModal] = React.useState(false);



    // // Add this useEffect hook to trigger a rerender on tab change
    // React.useEffect(() => {
    //   // You can add any logic here that you want to execute when the tab changes
    //   console.log(`Tab changed to index ${value}`);
    //  detailedOrdersTableDataSetter(addressSelected)
    // }, [value,portfolioValueAssumption,addressSelected,slippagePercentage,tradeFeesPercentage,multiplierAssumption]); // This effect runs whenever 'value' changes

   
  const handleChange = (event, newValue) => {
    // simulateCalculation()
    setValue(newValue);
    setTabState(newValue)
    if(value === 0){
      setDetailedOrdersTableData([])
    }
    if(value === 1){
      setDailyReturnTableData([])
    }
    detailedOrdersTableDataSetter(addressSelected)
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
        className='border-b border-[#D1D5DB]'
        value={value} onChange={handleChange} aria-label="basic tabs example">
          {items?.map((item,index)=>(
             <Tab 
             className='!capitalize !text-[#393939]'
             label={item} {...a11yProps(`${index}`)} />
          ))}
        </Tabs>
      </Box>
      {content?.map((item,index)=>(
         <CustomTabPanel value={value} index={index}>
         {item}
       </CustomTabPanel>
      ))}
       {calculating &&  <Modal
        modalContainerClassName='!w-[300px] !h-[300px]'
        // containerClassName='!w-[300px]'
        >
           <div className="flex flex-col h-screen w-full items-center justify-center ">
            <l-jelly size="40" speed="0.9" color="black"></l-jelly>
            <p className='text-black'>Calculating Data...</p>
          </div>
        </Modal>}
     
      {/* <CustomTabPanel value={value} index={1}>
        Item Two
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        Item Three
      </CustomTabPanel> */}
    </Box>
  );
}