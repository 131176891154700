import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";

const PieChart = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <ResponsivePie
      
      data={data}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.black[500],
            },
          },
          legend: {
            text: {
              fill: colors.black[500],
            },
          },
          ticks: {
            line: {
              stroke: colors.black[500],
              strokeWidth: 1,
            },
            text: {
              fill: colors.black[500],
              fontFamily:"Plus Jakarta Sans",
              fontWeight:"bolder"
            },
          },
        },
        legends: {
          text: {
            fill: colors.black[500],
            fontFamily:"Plus Jakarta Sans",
            fontWeight:'bolder'
          },
        },
      }}
      // colors={[colors.blueAccent[500], colors.greenAccent[500]]}
      colors={['#F7931A', '#627EEA']}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        startAngle={-180}
        sortByValue={true}
        innerRadius={0.7}
        padAngle={4}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      enableArcLinkLabels={false}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor={{ from: 'color', modifiers: [] }}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      enableArcLabels={false}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#393939",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#393939",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      legends={[
        {

            anchor: 'top-left',
            direction: 'column',
            justify: false,
            translateX: -50,
            translateY: 0,
            itemWidth: 100,
            itemHeight: 20,
            itemsSpacing: 0,
            symbolSize: 20,
            itemDirection: 'left-to-right'
        }
    ]}
    />
  );
};

export default PieChart;
