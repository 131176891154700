
import { useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {FaBackward, FaCopy, FaTimes} from 'react-icons/fa'
import DailyReturnsContext from "../../context/DailyReturnsContext";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "sonner";
import Orders from "./Orders";
import DailyReturnsTable from "./DailyReturnsTable";
import Modal from "../modal";
import { CSVLink } from "react-csv";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import PerformanceTabs from "../tabs/PerformanceTabs";
import StatsTable from "./StatsTable";




function PerformanceData({performanceData,setPerformanceData}) {
    const {
      dailyReturnTableData,
      setDailyReturnTableData,
      addressSelected,
      setAddressSelected,
      positionsData,
      setValue,
      tabState,
      setTabState,
      slippagePercentage,
      tradeFeesPercentage,
      portfolioValueAssumption,
      multiplierAssumption,
      getAllOrdersData,
      getDetailedOrders,
      getEthDetailedOrders,
      detailedOrdersTableData, setDetailedOrdersTableData,
      ethOrders,setEthOrders,
      allOrders,setAllOrders,
      calculating,setCalculating,
      detailedOrdersTableDataSetter,
      setPieChartDataData,setStatData
    } = useContext(DailyReturnsContext);
    // const [detailedOrdersTableData, setDetailedOrdersTableData] = useState([]);
    // const [ethOrders,setEthOrders]= useState([])

    // const [allOrders,setAllOrders] = useState([])
    const [download,setDownload] = useState(false)
    const [rerenderCount, setRerenderCount] = useState(0); // Counter state
    const [statShow,setStatShow] = useState(false)
    const navigate = useNavigate()


    console.log(portfolioValueAssumption)
    console.log(tradeFeesPercentage)
    console.log(slippagePercentage)
    console.log(multiplierAssumption)
    


    const dailyReturnsColumns = [
      {
        key: "btc_date",
        label: "Day"
      },
      {
        key: "btc_price",
        label: "End of Day Price(BTC)",
      },
      {
        key: "eth_price",
        label: "End of Day Price(ETH)"
      },
      
      {
        key: "total_position_size_btc",
        label: "Inventory (BTC)"
      },
      {
        key: "total_position_size_eth",
        label: "Inventory (ETH)"
      },
      {
        key: "avg_cost_basis_btc",
        label: "Average Cost Basis (BTC)"
      },
      {
        key: "avg_cost_basis_eth",
        label: "Average Cost Basis (ETH)"
      },
      {
        key: "realized_pnl_btc",
        label: "Realized Pnl (BTC)"
      },
      {
        key: "realized_pnl_eth",
        label: "Realized Pnl (ETH)"
      },
      {
        key: "unrealized_pnl_btc",
        label: "Unrealized Pnl (BTC)"
      },
      {
        key: "unrealized_pnl_eth",
        label: "Unrealized Pnl (ETH)"
      },
      {
        key: "slippage_trade_fees_btc",
        label: "Slippage Trade Fees (BTC)"
      },
      {
        key: "slippage_trade_fees_eth",
        label: "Slippage Trade Fees (ETH)"
      },
      {
        key: "cumulative_slippage_trade_fees",
        label: "Cummulative Slippage &  Trade Fees"
      },
      {
        key:'portfolio_value',
        label: 'Portfolio Value'
      },
      {
        key:'returns',
        label: 'Returns (%)'
      }
      // avg_cost_basis: 0, // Initialize with default values
      //         realized_pnl: 0,
      //         unrealized_pnl: 0,
      //         slippage_trade_fees: 0,
      //         cumulative_slippage_trade_fees: 0,
    ];
  
    const detailedOrdersColumns = [
      {
        key: "position_id",
        label: "Trade Id",
    
      },
      {
        key: "leverage",
        label: "Order Leverage",
       
      },
      {
        key: "order_type",
        label: "Order Type",
      },
      {
        key: "asset",
        label: "Asset",
      },
      {
        key: "processed_time",
        label: "Trade Time",
  
      },{
        key: "portfolio_value",
        label: "Portfolio Value At Trade Beginnning",
      },
      {
        key: "price",
        label: "Price",
      },
      {
        key: "order_size_usd",
        label: "Order Size ($)",
      },
      {
        key: "order_size_asset",
        label: "Order Size (token)",
      
      },
      // {
      //   key: "total_position_size_eth",
      //   label: "Total Position Size(ETH)",
      // },
      {
        key: "total_position_size_btc",
        label: "Total Position Size(BTC)",
      },
      // {
      //   key: "market_value_eth",
      //   label: "Market Value(ETH)"
      // },
      {
        key: "market_value_btc",
        label: "Tokens Held ($)"
      },
      // {
      //   key: "cost_basis_eth",
      //   label: "Cost Basis(ETH)",
      // },
      // {
      //   key: "cost_basis_btc",
      //   label: "Cost Basis(BTC)",
      // },
      {
        key: "avg_cost_basis",
        label: "Avg Cost Basis After Order",
      },
      {
        key: "realized_pnl_btc",
        label: "Realized Pnl",
      },
      {
        key: "unrealized_pnl",
        label: "Unrealized Pnl",
      },
      {
        key:'slippage_trade_fees',
        label:'Slippage And Trade Fees'
      },
      {
        key:'cumulative_slippage_trade_fees',
        label:'Cumulative Fees'
      }
    ];

  
  const processedDailyReturnTableData = dailyReturnTableData.map(item => ({
    ...item,
    btc_date: moment(item.btc_date).format('YYYY-MM-DD') // Adjust the format as needed
}));
// 5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t
// Trigger detailedOrdersTableDataSetter on initial render
useEffect(() => {
  console.log("******************addressSelected", addressSelected);
  
  setCalculating(true)
  if (rerenderCount < 3) { // Check if the effect has run less than twice
    detailedOrdersTableDataSetter(addressSelected);
    setRerenderCount((prevCount) => prevCount + 1); // Increment rerender count
  }
  setCalculating(false)
}, [rerenderCount, addressSelected]);

useEffect(() => {
  detailedOrdersTableDataSetter(addressSelected);
}, [
  portfolioValueAssumption,
  addressSelected,
  slippagePercentage,
  tradeFeesPercentage,
  multiplierAssumption,
]);


  return (
    <>
     {statShow && 
      <div className="fixed left-0 top-0 z-[10000] flex h-full w-full items-center justify-center bg-[rgba(0,0,0,0.3)] ">
        <div className="ml-[100px] h-full w-[100%] overflow-y-scroll bg-white p-6">
      <StatsTable
                statShow={statShow}
                setStatShow={setStatShow}
      />
    </div>
    </div>
     }
      <div className="fixed left-0 top-0 z-[100] flex h-full w-full items-center justify-center bg-[rgba(0,0,0,0.3)] ">
        <div className="ml-[50px] h-full w-[100%] overflow-y-scroll bg-white p-6">
          <div className="mb-[30px] flex w-full items-center justify-between ">
            <div
              className="cursor-pointer"
              onClick={() => {
                setAddressSelected("");
                setDetailedOrdersTableData([]);
                setEthOrders([]);
                setDailyReturnTableData([]);
                setPieChartDataData([])
                setStatData([])
              }}
            >
              <FaBackward color="black" size={30} />
            </div>
            <p className="space-grotesk text-[1.1rem] font-bold text-[#393939]">
              Performance Data
            </p>
            <div
              className="cursor-pointer"
              onClick={() => {
                setAddressSelected("");
                setDetailedOrdersTableData([]);
                setEthOrders([]);
                setDailyReturnTableData([]);
                setPieChartDataData([])
                setStatData([])
              }}
            >
              <FaTimes color="black" size={30} />
            </div>
          </div>

          <div className="flex w-[100%] flex-col">
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center">
                <p className="text-[1.2rem] font-semibold capitalize text-[#393939]">
                  {addressSelected}
                </p>

                <CopyToClipboard
                  onCopy={() => toast.success("Copied")}
                  text={addressSelected}
                >
                  <FaCopy
                    className="ml-4 cursor-pointer"
                    color="black"
                    size={25}
                  />
                </CopyToClipboard>
              </div>

              <div>
                {/* <CSVDownload data={detailedOrdersTableData} target="_blank" />; */}
                {/* <button
                  className="mr-4 mt-4 self-center rounded-lg bg-[#393939] p-2 text-white"
                  onClick={() => setStatShow(true)}
                >
                  View Stats
                </button> */}
                {tabState !== 1 && (
                  <button
                    className="mr-4 mt-4 self-center rounded-lg bg-[#393939] p-2 text-white"
                    onClick={() => navigate("/tearsheet")}
                  >
                    Show Tearsheet
                  </button>
                )}
                <button
                  className="mr-4 mt-4 self-center rounded-lg bg-[#393939] p-2 text-white"
                  onClick={() => setDownload(true)}
                >
                  Download CSV
                </button>
              </div>
            </div>

         
            <PerformanceTabs
              tabState={tabState}
              setTabState={setTabState}
              getEthDetailedOrders={getEthDetailedOrders}
              portfolioValueAssumption={portfolioValueAssumption}
              setEthOrders={setEthOrders}
              setAllOrders={setAllOrders}
              getAllOrdersData={getAllOrdersData}
              getDetailedOrders={getDetailedOrders}
              detailedOrdersTableData={detailedOrdersTableData}
              setDetailedOrdersTableData={setDetailedOrdersTableData}
              items={["Daily Returns", "Orders And Portfolio"]}
              content={[
                <DailyReturnsTable
                  users={dailyReturnTableData}
                  columns={dailyReturnsColumns}

                  //   slots={{ toolbar: GridToolbar }}
                />,
                <Orders
                  allOrdersData={allOrders}
                  detailedOrdersColumns={detailedOrdersColumns}
                  detailedOrdersTableData={detailedOrdersTableData}
                  setDetailedOrdersTableData={setDetailedOrdersTableData}
                  ethOrders={ethOrders}
                />,
              ]}
            />
            {/* {!dailyReturnTableData && <SkeletonTable/>}
         {!detailedOrdersTableData && <SkeletonTable/>} */}
          </div>
        </div>
      </div>

      {download && (
        <Modal modalContainerClassName="flex flex-col items-center justify-start !w-[350px]">
          <div className="mb-6 flex items-center justify-between">
            <p className="text-[1.2rem] font-semibold text-[#393939]">
              Download CSVs
            </p>
            <FaTimes onClick={() => setDownload(false)} color="#393939" />
          </div>
          <div>
            <CSVLink
              data={detailedOrdersTableData}
              filename={`Order Data(BTC) - ${addressSelected}.csv`}
              className="mt-4 w-full self-center rounded-lg bg-[#393939] p-2 text-white"
              target="_blank"
            >
              Download BTC Order data
            </CSVLink>
          </div>

          <div className="mt-10">
            <CSVLink
              data={ethOrders}
              filename={`Order Data(ETH) - ${addressSelected}.csv`}
              className="mt-4 self-center rounded-lg bg-[#393939] p-2 text-white"
              target="_blank"
            >
              Download ETH Order data
            </CSVLink>
          </div>

          <div className="mt-10">
            <CSVLink
              data={processedDailyReturnTableData}
              filename={`Daily Returns - ${addressSelected}.csv`}
              className="mt-4 self-center rounded-lg bg-[#393939] p-2 text-white"
              target="_blank"
            >
              Download Daily Returns data
            </CSVLink>
          </div>

          {/* <CSVLink
            data={ethOrders}
            filename={"Order Data.csv"}
           className="mt-4 self-center rounded-lg bg-[#393939] p-2 text-white"
            target="_blank"
          >
            Download ETH Order data
          </CSVLink>
          <CSVLink
            data={detailedOrdersTableData}
            filename={"Order Data.csv"}
           className="mt-4 self-center rounded-lg bg-[#393939] p-2 text-white"
            target="_blank"
          >
            Download Daily Returns data
          </CSVLink> */}
        </Modal>
      )}
      {calculating && (
        <Modal
          modalContainerClassName="!w-[300px] !h-[300px]"
          // containerClassName='!w-[300px]'
        >
          <div className="flex h-screen w-full flex-col items-center justify-center ">
            <l-jelly size="40" speed="0.9" color="black"></l-jelly>
            <p className="text-black">Calculating Data...</p>
          </div>
        </Modal>
      )}
    </>
  );
}

export default PerformanceData

