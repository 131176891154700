import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import DailyReturnsContext from "../../context/DailyReturnsContext";
import TableComponent from "../orders/TableComponent";
import { Spinner } from "@nextui-org/react";
import { FaRegTimesCircle, FaTimes } from "react-icons/fa";
import SkeletonTable from "../skeletons/SkeletonTable";
import { IoMdRefresh } from "react-icons/io";
import { toast } from "sonner";
import Modal from "../modal";


const MinerPerformance = () => {
  const theme = useTheme();
  const {
    addressSelected,
    setAddressSelected,
    setSlippagePercentage,
    setTradeFeesPercentage,
    portfolioInput, setPortfolioInput,
    multiplierInput, setMultiplierInput,
    slippageInput, setSlippageInput,
    tradeFeesInput, setTradeFeesInput,
    setPortfolioValueAssumption,
    setMultiplierAssumption,
    detailedOrdersTableDataSetter,
    dailyReturnTableData,
    positionsForPerformance, setPositionsData, getMinerData
  } = useContext(DailyReturnsContext);
  // const [positionsForPerformance, setPositionsData] = useState({});
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [performanceData, setPerformanceData] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [page, setPage] = useState(1);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState('ascending');
  const [showModal, setShowModal] = useState(false)


  const sortData = (data, key, direction = 'descending') => {
    return [...data].sort((a, b) => {
      const first = a[key];
      const second = b[key];
      let cmp = 0;

      if (typeof first === 'number' && typeof second === 'number') {
        cmp = first - second;
      } else if (typeof first === 'string' && typeof second === 'string') {
        cmp = first.localeCompare(second);
      } else if (!isNaN(Date.parse(first)) && !isNaN(Date.parse(second))) {
        cmp = new Date(first) - new Date(second);
      }
      return direction === 'descending' ? -cmp : cmp;
    });
  };

  const handleSortChange = (e) => {
    const key = e.target.value;
    setSortKey(key);
    const sortedData = sortData(tableData, key, sortDirection);
    setTableData(sortedData);
  };

  const handleDirectionChange = (e) => {
    const direction = e.target.value;
    setSortDirection(direction);
    const sortedData = sortData(tableData, sortKey, direction);
    setTableData(sortedData);
  };

  const columns = [
    { key: "miner_public_key", label: "Public key" },
    { key: "cagr", label: "CAGR" },
    { key: "max_drawdown", label: "Max Drawdown" },
    { key: "sharpe_ratio", label: "Sharpe" },
    { key: "sortino_ratio", label: "Sortino" },
    { key: "calmar_ratio", label: "Calmar" },
    { key: "avg_returns_1d", label: "1 Day" },
    { key: "avg_returns_1w", label: "1 Week" },
    { key: "avg_returns_30d", label: "30 Days" },
    { key: "avg_returns_60d", label: "60 Days" },
    { key: "avg_returns_90d", label: "90 Days" },
    { key: "active_from", label: "Active from" },
    { key: "last_active", label: "Last active" },
    { key: "view_details", label: "" },
  ];


  const getMinerPerformanceData = async () => {

    if (!positionsForPerformance) return;
    setLoading(true); // Show loader

    // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const fetchMinerData = async (minerKey, filteredPositions, index) => {

      const returnsData = await detailedOrdersTableDataSetter(minerKey);

      try {
        if (returnsData.length > 1) {
          // const response = await fetch("http://localhost:5000/calculate", {
          const response = await fetch("https://dmbppvk3cp.ap-south-1.awsapprunner.com/calculate", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ daily_returns_data: returnsData }),
          });

          if (!response.ok) {
            throw new Error(`Failed with status ${response.status}`);
          }

          const result = await response.json();

          return {
            id: index + 1,
            miner_public_key: minerKey,
            sharpe_ratio: result.sharpe || 0,
            sortino_ratio: result.sortino || 0,
            calmar_ratio: result.calmar || 0,
            cagr: result.cagr || 0,
            max_drawdown: result.max_drawdown || 0,
            avg_returns_1d: result.avg_returns_1d || 0,
            avg_returns_1w: result.avg_returns_1w || 0,
            avg_returns_30d: result.avg_returns_30d || 0,
            avg_returns_60d: result.avg_returns_60d || 0,
            avg_returns_90d: result.avg_returns_90d || 0,
            avg_return: result.avg_return || 0,
            active_from: new Date(filteredPositions[filteredPositions.length - 1].open_ms).toLocaleString('en-US', {
              month: 'numeric',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              hour12: true
            }),
            last_active: new Date(filteredPositions[0].open_ms).toLocaleString('en-US', {
              month: 'numeric',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              hour12: true
            }),
          };
        } else {
          return {
            id: index + 1,
            miner_public_key: minerKey,
            sharpe_ratio: 0,
            sortino_ratio: 0,
            calmar_ratio: 0,
            cagr: 0,
            max_drawdown: 0,
            avg_returns_1d: 0,
            avg_returns_1w: 0,
            avg_returns_30d: 0,
            avg_returns_60d: 0,
            avg_returns_90d: 0,
            avg_return: 0,
            active_from: new Date(filteredPositions[filteredPositions.length - 1].open_ms).toLocaleString('en-US', {
              month: 'numeric',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              hour12: true
            }),
            last_active: new Date(filteredPositions[0].open_ms).toLocaleString('en-US', {
              month: 'numeric',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              hour12: true
            }),
          };
        }

      } catch (error) {
        console.error(`Error fetching metrics for ${minerKey}:`, error);
        return null; // Return null to handle gracefully in the data aggregation step.
      }
    };

    const data = [];
    let count = 0;
    for (const [index, minerKey] of Object.keys(positionsForPerformance).entries()) {
      // if(count<=3) {
      const filteredPositions = positionsForPerformance[minerKey].positions.filter(
        (position) => position.trade_pair.includes("ETHUSD") || position.trade_pair.includes("BTCUSD")
      );

      if (filteredPositions.length > 0) {
        const minerData = await fetchMinerData(minerKey, filteredPositions, index);

        if (minerData) {
          data.push(minerData);
          setTableData((prevData) => [...prevData, minerData]);
        }
      }
    }
    // Store the complete fetched data in sessionStorage
    sessionStorage.setItem("minerPerformanceData", JSON.stringify(data));
    setLoading(false); // Hide loader
  };

  const handleSearch = () => {
    setPage(1)
    const results = tableData.filter((data) => {
      return data.miner_public_key === searchTerm
    })
    setTableData(results)
  }


  console.log(tableData)

  // Get data from localStorage on page load
  useEffect(() => {
    const cachedData = sessionStorage.getItem("minerPerformanceData");
    if (cachedData) {
      setTableData(JSON.parse(cachedData));
    } else {

      getMinerPerformanceData();

    }
  }, [positionsForPerformance]);


  const calculateWithAssumptions = () => {
    setPortfolioValueAssumption(Number(portfolioInput));
    setTradeFeesPercentage(Number(tradeFeesInput));
    setSlippagePercentage(Number(slippageInput));
    setMultiplierAssumption(Number(multiplierInput));
    // detailedOrdersTableDataSetter(addressSelected)
    setShowModal(false)
    console.log('called')
  }



  return (
    <Box>
      {loading && (
        <div style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <Spinner size="lg" color="white" />
        </div>
      )}
      <div className="flex w-full items-center justify-between p-4 pb-[4px]">
        <p className="text-[1.3rem] font-bold text-[#393939]">
          Miner Performance
        </p>
        <IoMdRefresh
          onClick={() => {
            sessionStorage.removeItem("minerPerformanceData");
            sessionStorage.clear();
            setTableData([])
            toast.success('Refreshing')
            setPositionsData(null)
            getMinerData()
            setSearchTerm('')

          }}
          color="#393939"
          size={25}
          className="cursor-pointer"
        />
      </div>


      <div className="h-[1px] w-full bg-[#D1D5DB]"></div>

      <Box>
        <div className="h-fit p-4">
          <div className='w-full flex justify-between my-2 items-center'>
            <div>
              <p className='text-[#393939]'>Sort By:</p>
              <select
                value={sortKey}
                onChange={handleSortChange}
                className='capitalize border p-2 rounded-lg text-[#393939]'>
                {["Select Option", "last_active", "active_from", "avg_return_thirty_days", "avg_return", "sharpe_ratio", "sortino_ratio", "drawdown", "total_number_of_days_active"].map((item) => (
                  <option key={item} value={item}>
                    {item.replace(/_/g, " ")}
                  </option>
                ))}
              </select>
              <select
                value={sortDirection}
                onChange={handleDirectionChange}
                className='capitalize border p-2 rounded-lg text-[#393939] ml-2'>
                {['Select Option', 'ascending', 'descending'].map((direction) => (
                  <option key={direction} value={direction}>
                    {direction}
                  </option>
                ))}
              </select>
            </div>

            <button
              onClick={() => {
                setShowModal(true);
              }}
              className="mt-4 self-center rounded-lg bg-[#393939] p-2 text-white"
            >
              Assumptions
            </button>
          </div>
          {/* <div className='w-full flex items-center justify-end'>
             
                </div> */}
          <div className="flex h-fit w-full items-center gap-2">
            <div className="my-3 flex w-full items-center rounded-xl border bg-white p-2 focus:border-2 focus:border-[#393939]">

              <input
                placeholder="Search By Address..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className=" h-[30px] w-full  text-[#393939] outline-none outline-0  transition-all delay-[.1s]"
              />
              <FaRegTimesCircle
                onClick={() => {
                  getMinerPerformanceData()
                  setSearchTerm('')
                  if (tableData.length === 0) {
                    toast.error('Not Found')
                  }
                }}
                color="#393939" className="cursor-pointer" />
            </div>

            <button
              disabled={searchTerm.length < 1}
              onClick={handleSearch}
              className="rounded-md bg-[#393939] p-2 text-white disabled:opacity-[.3] disabled:cursor-not-allowed"
            >
              Search
            </button>
          </div>

          {(!positionsForPerformance) && <SkeletonTable />}

          {positionsForPerformance && <TableComponent
            users={tableData}
            columns={columns}
            page={page}
            setPage={setPage}
            setPerformanceData={setPerformanceData}
            performanceData={performanceData}
            addressSelected={addressSelected}
            setAddressSelected={setAddressSelected}
          />}
        </div>
      </Box>

      {showModal && (
        <Modal modalContainerClassName="!w-[350px]">
          <div className="mb-6 flex items-center justify-between">
            <p className="text-[1.2rem] font-semibold text-[#393939]">
              Assumptions
            </p>
            <FaTimes onClick={() => setShowModal(false)} color="#393939" />
          </div>
          <div className="flex flex-col">
            <label htmlFor="port_val">
              <p className="text-[#393939]">Portfolio Value:</p>
              <input
                type="number"
                value={portfolioInput}
                onChange={(e) => setPortfolioInput(e.target.value)}
                id="port_val"
                className="w-full rounded-lg border p-2 text-[#393939] outline-none"
              />
            </label>
            <label htmlFor="oz">
              <p className="mt-4 text-[#393939]">Order Size Multiplier:</p>
              <input
                type="number"
                value={multiplierInput}
                onChange={(e) => setMultiplierInput(e.target.value)}
                id="oz"
                className="w-full rounded-lg border p-2 text-[#393939] outline-none"
              />
            </label>
            <label htmlFor="slippage">
              <p className="mt-4 text-[#393939]">Slippage(% of trade size):</p>
              <input
                type="number"
                value={slippageInput}
                onChange={(e) => setSlippageInput(e.target.value)}
                id="slippage"
                className="w-full rounded-lg border p-2 text-[#393939] outline-none"
              />
            </label>
            <label htmlFor="trade">
              <p className="mt-4 text-[#393939]">
                Trade Fees: (% of trade size)
              </p>
              <input
                type="number"
                value={tradeFeesInput}
                onChange={(e) => setTradeFeesInput(e.target.value)}
                id="trade"
                className="w-full rounded-lg border p-2 text-[#393939] outline-none"
              />
            </label>

            <button
              onClick={calculateWithAssumptions}
              className="mt-4 self-center rounded-lg bg-[#393939] p-2 text-white"
            >
              Update Table
            </button>
          </div>
        </Modal>
      )}
    </Box>
  );
};

export default MinerPerformance;

