import React, { useContext } from 'react'
import { Box, Button, IconButton, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
// import { positionsData } from "../../data/mockPositionsData";
import StatBox from "../../components/StatBox";
import PieChart from "../../components/PieChart";
import { useEffect, useState } from "react";
import { fetchDashboardData } from "../../functions";
import DailyReturnsContext from '../../context/DailyReturnsContext';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'sonner';
import { FaCopy } from 'react-icons/fa';
import { Spinner } from '@nextui-org/react';
import SkeletonElement from '../skeletons/SkeletonBase';
import SkeletonBox from '../skeletons/SkeletonBox';
import AddressPie from './AddressPie';
import { mockDataPositions } from '../../data/mockPositionsData';


function DashboardComponent({type}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [statData, setStatData] = useState([]);
    const [pieChartData, setPieChartDataData] = useState([]);
    const [loading, setLoading] = useState(false);
    const {positionsData} = useContext(DailyReturnsContext)

    const tarvisAndTimelessAddress =
    "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t";
  
  const tarvisTwo = '5EZSgUEHCZX4jYxFXmDMJ2vraS12t5YQCcFYS87h2PgbQnyD'




    const getDashboardData = async () => {
      if(positionsData){
        setLoading(true);
        console.log('1',Object.keys(positionsData).length)
        console.log('2',Object.keys(mockDataPositions).length)
        console.log('1',typeof(positionsData))
      // const data = await fetchDashboardData("dashboard");
      // console.log(data);
      console.log('daaa',positionsData[tarvisAndTimelessAddress])
      if (type === "Tarvis") {

        const tarvisBtcPositionsData = positionsData[
          tarvisAndTimelessAddress
        ].positions.filter((item) => item.trade_pair.includes("BTCUSD"));
        
        console.log('btc length',tarvisBtcPositionsData.length)
    
        // // BTC
        // const tarvisBtcPositionsData = positionsData[
        //   tarvisAndTimelessAddress
        // ].positions.filter((item) => item.trade_pair.includes("BTCUSD"));
        const tarvisBtcNoOfOpenPositions = tarvisBtcPositionsData.filter(
          (item) => !item.is_closed_position
        ).length;
        const tarvisBtcOrders = tarvisBtcPositionsData.map((item) => item.orders);
        const tarvisBtcNoOfOrders = [].concat(...tarvisBtcOrders).length;
  
        // ETH
        const tarvisEthPositionsData =  positionsData[
          tarvisAndTimelessAddress
        ].positions.filter((item) => item.trade_pair.includes("ETHUSD"));
        const tarvisEthNoOfOpenPositions = tarvisEthPositionsData.filter(
          (item) => !item.is_closed_position
        ).length;
        const tarvisEthOrders = tarvisEthPositionsData.map((item) => item.orders);
        const tarvisEthNoOfOrders = [].concat(...tarvisEthOrders).length;
  
        const tarvisAvgReturn =
          positionsData[tarvisAndTimelessAddress].thirty_day_returns;
  
        setStatData([
          {
            title: tarvisBtcPositionsData.length,
            subtitle: "# of btc positions",
            id: "btc-positions",
          },
          {
            title: tarvisEthPositionsData.length,
            subtitle: "# of eth positions",
            id: "eth-positions",
          },
          {
            title: tarvisBtcNoOfOpenPositions,
            subtitle: "# of btc open positions",
            id: "btc-open-positions",
          },
          {
            title: tarvisEthNoOfOpenPositions,
            subtitle: "# of eth open positions",
            id: "eth-open-positions",
          },
          {
            title: tarvisBtcNoOfOrders,
            subtitle: "# btc of orders",
            id: "btc-orders",
          },
          {
            title: tarvisEthNoOfOrders,
            subtitle: "# of eth orders",
            id: "eth-orders",
          },
          {
            title: tarvisAvgReturn.toFixed(6),
            subtitle: "avg return",
            id: "avg-return",
          },
        ]);
  
        setPieChartDataData([
          {
            id: "pie-chart-1",
            data: [
              {
                id: tarvisAndTimelessAddress.substring(0, 16) + "...",
                label: tarvisAndTimelessAddress.substring(0, 16) + "...",
                value:
                  tarvisBtcPositionsData.length + tarvisEthPositionsData.length,
              },
            ],
          },
          {
            id: "pie-chart-2",
            data: [
              {
                id: "bitcoin",
                label: "BTC",
                value: tarvisBtcPositionsData.length,
              },
              {
                id: "ethereum",
                label: "ETH",
                value: tarvisEthPositionsData.length,
              },
            ],
          },
        ]);
      } 
      else if (type === 'TarvisTwo'){
         // BTC
         const tarvisBtcPositionsData = positionsData[
          tarvisTwo
        ].positions.filter((item) => item.trade_pair.includes("BTCUSD"));
        const tarvisBtcNoOfOpenPositions = tarvisBtcPositionsData.filter(
          (item) => !item.is_closed_position
        ).length;
        const tarvisBtcOrders = tarvisBtcPositionsData.map((item) => item.orders);
        const tarvisBtcNoOfOrders = [].concat(...tarvisBtcOrders).length;
  
        // ETH
        const tarvisEthPositionsData = positionsData[
          tarvisTwo
        ].positions.filter((item) => item.trade_pair.includes("ETHUSD"));
        const tarvisEthNoOfOpenPositions = tarvisEthPositionsData.filter(
          (item) => !item.is_closed_position
        ).length;
        const tarvisEthOrders = tarvisEthPositionsData.map((item) => item.orders);
        const tarvisEthNoOfOrders = [].concat(...tarvisEthOrders).length;
  
        const tarvisAvgReturn =
          positionsData[tarvisTwo].thirty_day_returns;
  
        setStatData([
          {
            title: tarvisBtcPositionsData.length,
            subtitle: "# of btc positions",
            id: "btc-positions",
          },
          {
            title: tarvisEthPositionsData.length,
            subtitle: "# of eth positions",
            id: "eth-positions",
          },
          {
            title: tarvisBtcNoOfOpenPositions,
            subtitle: "# of btc open positions",
            id: "btc-open-positions",
          },
          {
            title: tarvisEthNoOfOpenPositions,
            subtitle: "# of eth open positions",
            id: "eth-open-positions",
          },
          {
            title: tarvisBtcNoOfOrders,
            subtitle: "# btc of orders",
            id: "btc-orders",
          },
          {
            title: tarvisEthNoOfOrders,
            subtitle: "# of eth orders",
            id: "eth-orders",
          },
          {
            title: tarvisAvgReturn.toFixed(6),
            subtitle: "avg return",
            id: "avg-return",
          },
        ]);
  
        setPieChartDataData([
          {
            id: "pie-chart-1",
            data: [
              {
                id: tarvisTwo.substring(0, 16) + "...",
                label: tarvisTwo.substring(0, 16) + "...",
                value:
                  tarvisBtcPositionsData.length + tarvisEthPositionsData.length,
              },
            ],
          },
          {
            id: "pie-chart-2",
            data: [
              {
                id: "bitcoin",
                label: "BTC",
                value: tarvisBtcPositionsData.length,
              },
              {
                id: "ethereum",
                label: "ETH",
                value: tarvisEthPositionsData.length,
              },
            ],
          },
        ]);
      }
      else {
        const clonedData = JSON.parse(JSON.stringify(positionsData));
        delete clonedData[tarvisAndTimelessAddress];
        delete clonedData[tarvisTwo];
  
        const othersData = Object.keys(clonedData).map(
          (item) => clonedData[item]
        );
  
        // BTC
        const othersBtcPositionsData2d = othersData.map((item) =>
          item.positions.filter((item) => item.trade_pair.includes("BTCUSD"))
        );
        const othersBtcPositionsData = [].concat(...othersBtcPositionsData2d);
        const othersBtcNoOfOpenPositions = othersBtcPositionsData.filter(
          (item) => !item.is_closed_position
        ).length;
        const othersBtcOrders = othersBtcPositionsData.map((item) => item.orders);
        const othersBtcNoOfOrders = [].concat(...othersBtcOrders).length;
  
        // ETH
        const othersEthPositionsData2d = othersData.map((item) =>
          item.positions.filter((item) => item.trade_pair.includes("ETHUSD"))
        );
        const othersEthPositionsData = [].concat(...othersEthPositionsData2d);
        const othersEthNoOfOpenPositions = othersEthPositionsData.filter(
          (item) => !item.is_closed_position
        ).length;
        const othersEthOrders = othersEthPositionsData.map((item) => item.orders);
        const othersEthNoOfOrders = [].concat(...othersEthOrders).length;
  
        let sum = 0;
        for (let item of othersData) {
          sum += item.thirty_day_returns;
        }
        const othersAvgReturn = sum / othersData.length;
  
        const othersAddress = Object.keys(clonedData);
        const mainAddress = othersAddress.splice(0, 10);
  
        const mainAddressPieData = mainAddress.map((item) => ({
          id: item.substring(0, 16) + "...",
          label: item.substring(0, 16) + "...",
          value: clonedData[item].positions.filter(
            (position) =>
              position.trade_pair.includes("ETHUSD") ||
              position.trade_pair.includes("BTCUSD")
          ).length,
        }));
  
        let otherAddressValue = 0;
        for (let item of othersAddress) {
          otherAddressValue += clonedData[item].positions.filter(
            (position) =>
              position.trade_pair.includes("ETHUSD") ||
              position.trade_pair.includes("BTCUSD")
          ).length;
        }
  
        setStatData([
          {
            title: othersBtcPositionsData.length,
            subtitle: "No of btc positions",
            id: "btc-positions",
          },
          {
            title: othersEthPositionsData.length,
            subtitle: "No of eth positions",
            id: "eth-positions",
          },
          {
            title: othersBtcNoOfOpenPositions,
            subtitle: "No of btc open positions",
            id: "open-positions",
          },
          {
            title: othersEthNoOfOpenPositions,
            subtitle: "No of eth open positions",
            id: "open-positions",
          },
          {
            title: othersBtcNoOfOrders,
            subtitle: "No of btc orders",
            id: "orders",
          },
          {
            title: othersEthNoOfOrders,
            subtitle: "No of eth orders",
            id: "orders",
          },
          {
            title: othersAvgReturn.toFixed(6),
            subtitle: "avg return",
            id: "avg-return",
          },
        ]);
  
        setPieChartDataData([
          {
            id: "pie-chart-1",
            data: [
              ...mainAddressPieData,
              {
                id: "Others",
                label: "Others",
                value: otherAddressValue,
              },
            ],
          },
          {
            id: "pie-chart-2",
            data: [
              {
                id: "bitcoin",
                label: "BTC",
                value: othersBtcPositionsData.length,
              },
              {
                id: "ethereum",
                label: "ETH",
                value: othersEthPositionsData.length,
              },
            ],
          },
        ]);
      }
  
      setLoading(false);
      }
      
    };
  
    useEffect(() => {
      getDashboardData();

      return () => {
        // Reset state when component unmounts
        setStatData([]);
        setPieChartDataData([]);
      };    
    }, [type,positionsData]);

    console.log(statData)
  return (
    <>
    
       {type ==='Tarvis' && <div className='flex mb-4 mt-[-20px]items-center'><p className='text-[1.3rem] text-[#393939] font-semibold '>{tarvisAndTimelessAddress}</p>
    <span> <CopyToClipboard
        onCopy={()=>toast.success('Copied')}
        text={tarvisAndTimelessAddress}>
        <FaCopy
        className='cursor-pointer ml-4'
        color="black"
        size={25}
        />
        </CopyToClipboard></span>
      </div>}
       {type ==='TarvisTwo' && <div className='flex mb-4 mt-[-20px]items-center'><p className='text-[1.3rem] text-[#393939] font-semibold '>{tarvisTwo}</p>
    <span> <CopyToClipboard
        onCopy={()=>toast.success('Copied')}
        text={tarvisTwo}>
        <FaCopy
        className='cursor-pointer ml-4'
        color="black"
        size={25}
        />
        </CopyToClipboard></span>
      </div>}
      {!positionsData && <SkeletonBox/>}
    <Box
    display="grid"
    gridTemplateColumns="repeat(9, 2fr)"
    gridAutoRows="110px"
    gap="20px"
    height='fit-content'
  >

    
    {/* ROW 1 */}
    {positionsData &&
      statData.map(({ title, subtitle, id }) => (
        <Box
          key={id}
          gridColumn="span 3"
          backgroundColor={subtitle.includes('btc')? colors.orange[500]:subtitle.includes('eth')?colors.indigo[500]:colors.black[500]}
          display="flex"
          height='fit-content'
          alignItems="center"
          justifyContent="space-between"
          borderRadius='12px'
          padding='20px'
        >
          <StatBox
            title={title}
            subtitle={subtitle}
            // progress="0.75"
            // increase="+14%"
            // icon={
            //   <EmailIcon
            //     sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
            //   />
            // }
          />
          {subtitle.includes('btc')?<img alt='btc' src='/assets/btc.png'/>:subtitle.includes('eth')?<img alt='eth' src='/assets/eth.png'/>:''}
          
        </Box>
      ))}
      </Box>
    
   <div className='flex items-center justify-between mt-10'>
   {pieChartData.map(({ id, data }) => (
      <Box
        width='49%'
        height='400px'
        key={id}
        gridColumn="span 4"
        gridRow="span 3"
        backgroundColor={`white`}
        borderRadius='12px'
        p="10px"
      >
        <Typography variant="h5" 
        color='#393939'
        fontWeight="600">
          No of positions / miner
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          height={"100%"}
        >
         {id === 'pie-chart-1'? <AddressPie data={data}/>: <PieChart 
          data={data} />} 
        </Box>
      </Box>
    ))}
   </div>
    
    </>
    
   
  
  )
}

export default DashboardComponent