import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import ProgressCircle from "./ProgressCircle";

const StatBox = ({ title, subtitle, icon, progress, increase, }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box 
    display='flex'
    flexDirection='column'
    alignItems='flex-start'
    justifyContent='flex-start'
    width="100%" >
      <Box
        display="flex"
        justifyContent={progress ? "space-between" : "center"}
      >
        <Box>
          {/* {icon} */}
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ color: colors.white[500] }}
          >
            {subtitle}
          </Typography>
        </Box>
        {progress && (
          <Box>
            <ProgressCircle progress={progress} />
          </Box>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent={increase ? "space-between" : "center"}
        mt="2px"
      >
        <Typography variant="h2" sx={{ fontWeight:'700',color: colors.white[500] }}>
          {title}
        </Typography>
        {/* {increase && (
          <Typography
            variant="h2"
            fontStyle="italic"
            sx={{ color: colors.greenAccent[600] }}
          >
            {increase}
          </Typography>
        )} */}
      </Box>
    </Box>
  );
};

export default StatBox;
