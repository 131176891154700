import React, { useContext, useState, useEffect } from 'react';
import DailyReturnsContext from '../../context/DailyReturnsContext';
import StatsTabular from './StatsTabular';
import { FaBackward, FaTimes } from 'react-icons/fa';

const columns = [
    { key: "btc_date", label: "Day" },
    { key: "btc_price", label: "End of Day Price(BTC)" },
    { key: "eth_price", label: "End of Day Price(ETH)" },
    { key: "returns", label: "30 day Avg return" },
    { key: "btcCummulative", label: "BTC Cummulative" },
    { key: "strategyCumulativeReturns", label: "Strategy Cumulative" },
    { key: "runningMax", label: "Running Max" },
    { key: "drawdown", label: "Drawdown" },
    { key: "strategy", label: "Strategy" },
    { key: "dDaysRunning", label: "D days Running" },
    { key: "rollingAvgDaily", label: "Rolling Average Daily" },
    { key:'stDev', label:'Standard Deviation' },
    { key:'sharpe', label:'Sharpe' }
];

function StatsTable({ statShow, setStatShow }) {
  const { dailyReturnTableData } = useContext(DailyReturnsContext);
  const [strategyData, setStrategyData] = useState([]);
  const [cagr, setCagr] = useState(null); // New state for CAGR
  const [sharpeRatio, setSharpeRatio] = useState(null); // New state for Sharpe Ratio
  const [maxDrawdown, setMaxDrawdown] = useState(null); // New state for max drawdown
  const [calmarRatio, setCalmarRatio] = useState(null); // New state for Calmar ratio
  const [sortinoRatio, setSortinoRatio] = useState(null); // State for Sortino Ratio

  useEffect(() => {
    let strategyCumulativeReturns = 1;
    let runningMax = 1;
    let dDaysRunning = 0;
    let btcCummulative = 1;
    let allReturns = [];
    let minDrawdown = 0; // Initialize for tracking the minimum drawdown
    
    const calculateStDev = (data) => {
      const mean = data.reduce((acc, curr) => acc + curr, 0) / data.length;
      const variance = data.reduce((acc, curr) => acc + Math.pow(curr - mean, 2), 0) / data.length;
      return Math.sqrt(variance).toFixed(6);
    };

    const calculateDownsideDeviation = (data) => {
        const negativeReturns = data.filter(value => value < 0); // Filter negative returns
        if (negativeReturns.length === 0) return 0;
        return calculateStDev(negativeReturns); // Calculate standard deviation of negative returns
    };
  
    const newStrategyData = dailyReturnTableData.map((entry, index) => {
      const { btc_date, btc_price, eth_price, returns } = entry;
  
      const dailyReturns = index === 0 ? 0 : (btc_price / dailyReturnTableData[index - 1].btc_price) - 1;
      if (index > 0) {
        btcCummulative *= (1 + dailyReturns);
      }
  
      strategyCumulativeReturns *= (returns + 1); 
      runningMax = Math.max(strategyCumulativeReturns, runningMax);
      const drawdown = (strategyCumulativeReturns - runningMax) / runningMax;
  
      if (strategyCumulativeReturns < runningMax) {
        dDaysRunning += 1; 
      } else {
        dDaysRunning = 0;
      }
  
      const strategy = (strategyCumulativeReturns - 1) * 100;
      allReturns.push(returns); // Collect all returns for Sharpe and Sortino calculation
  
      // Track the minimum drawdown
      minDrawdown = Math.min(minDrawdown, drawdown);
  
      const rollingAvgDaily = (() => {
        const availableDays = Math.min(index + 1, 180); 
        const dataToAverage = dailyReturnTableData
          .slice(index - availableDays + 1, index + 1)
          .map(item => item.returns);
        const avgReturns = dataToAverage.reduce((acc, curr) => acc + curr, 0) / availableDays;
        return avgReturns.toFixed(6);
      })();
  
      const stDev = (() => {
        const availableDays = Math.min(index + 1, 180);
        const dataForStDev = dailyReturnTableData
          .slice(index - availableDays + 1, index + 1)
          .map(item => item.returns);
  
        return calculateStDev(dataForStDev);
      })();
  
      const sharpe = (() => {
        const rollingAvgDailyFloat = parseFloat(rollingAvgDaily);
        const stDevFloat = parseFloat(stDev);
        if (stDevFloat === 0) return 0;
        return ((rollingAvgDailyFloat * Math.sqrt(365)) / stDevFloat).toFixed(6);
      })();
  
      return {
        btc_date,
        btc_price,
        eth_price,
        returns,
        dailyReturns: dailyReturns.toFixed(6),
        btcCummulative: btcCummulative.toFixed(8),
        strategyCumulativeReturns: strategyCumulativeReturns.toFixed(8),
        runningMax: runningMax.toFixed(8),
        drawdown: drawdown.toFixed(8),
        strategy: strategy.toFixed(2),
        dDaysRunning,
        rollingAvgDaily,
        stDev,
        sharpe
      };
    });
  
    setStrategyData(newStrategyData);
  
    // Set the max drawdown
    setMaxDrawdown(minDrawdown.toFixed(8));
  
    // Calculate CAGR
    const lastStrategyCumulativeReturns = Number(strategyCumulativeReturns); 
    const cagrCalculation = lastStrategyCumulativeReturns > 0
      ? (Math.pow(lastStrategyCumulativeReturns, 365 / dailyReturnTableData.length) - 1).toFixed(6)
      : 0;
    setCagr(Number(cagrCalculation) * 100); 
  
    // Calculate Sharpe Ratio
    const averageReturns = allReturns.length > 0 ? allReturns.reduce((acc, curr) => acc + curr, 0) / allReturns.length : 0;
    const totalStDev = calculateStDev(allReturns);
    const sharpeRatioCalculation = totalStDev > 0
      ? ((averageReturns * Math.sqrt(365)) / totalStDev).toFixed(6)
      : 0;
    setSharpeRatio(sharpeRatioCalculation);

    // Calculate Sortino Ratio
    const downsideDeviation = calculateDownsideDeviation(allReturns);
    const sortinoRatioCalculation = downsideDeviation > 0
      ? ((averageReturns * Math.sqrt(365)) / downsideDeviation).toFixed(6)
      : 0;
    setSortinoRatio(sortinoRatioCalculation);
  
    // Calculate Calmar ratio
    if (cagrCalculation > 0 && minDrawdown < 0) {
      const calmar = Math.abs(Number(cagrCalculation) / Math.abs(minDrawdown)).toFixed(6);
      setCalmarRatio(calmar);
    }else{
        setCalmarRatio(0)
    }

  }, [dailyReturnTableData]);

  return (
    <div className='text-black'>
      <div className="mb-[30px] flex w-full items-center justify-between ">
        <div 
        
        className="cursor-pointer" onClick={() => {
            setStrategyData([])
            setStatShow(false)
            }}>
          <FaBackward color="black" size={30} />
        </div>
        <h2 className='text-[1.3rem] font-semibold'>Stats</h2>
        <div className="cursor-pointer" onClick={() =>{
             setStrategyData([])
             setStatShow(false)
        }}>
          <FaTimes color="black" size={30} />
        </div>
      </div>
      
      <div>
        <h3 className='text-[1.3rem] font-semibold'>CAGR: {cagr}%</h3>
        <h3 className='text-[1.3rem] font-semibold'>Sharpe Ratio: {sharpeRatio}</h3>
        <h3 className='text-[1.3rem] font-semibold'>Sortino Ratio: {sortinoRatio}</h3> {/* Display Sortino Ratio */}
        <h3 className='text-[1.3rem] font-semibold'>Max Drawdown: {maxDrawdown * 100}%</h3>
        <h3 className='text-[1.3rem] font-semibold'>Calmar Ratio: {calmarRatio}</h3>
      </div>
      
      <div className='h-[400px] w-full overflow-x-auto'>
        <StatsTabular
          columns={columns}
          users={strategyData}
        />
      </div>
    </div>
  );
}

export default StatsTable;
