import React, { useState } from 'react';
import PerformanceTable from './PerformanceTable';

import OrdersTabs from '../tabs/OrdersTabs';


const detailedOrdersColumnsForEth = [
  {
    key: "position_id",
    label: "Trade Id",

  },
  {
    key: "leverage",
    label: "Order Leverage",
   
  },
  {
    key: "order_type",
    label: "Order Type",
  },
  {
    key: "asset",
    label: "Asset",
  },
  {
    key: "processed_time",
    label: "Processed Time",

  },
  {
    key: "portfolio_value",
    label: "Portfolio Value At Trade Beginnning",
  },

  {
    key: "price",
    label: "Price",
  },
  {
    key: "order_size_usd",
    label: "Order Size ($)",
  },
  {
    key: "order_size_asset",
    label: "Order Size (token)",
  
  },
  // {
  //   key: "total_position_size_eth",
  //   label: "Total Position Size(ETH)",
  // },

  {
    key: "total_position_size_eth",
    label: "Total Position Size(ETH)",
  },
  // {
  //   key: "market_value_eth",
  //   label: "Market Value(ETH)"
  // },
  {
    key: "market_value_eth",
    label: "Tokens Held ($)"
  },
  // {
  //   key: "cost_basis_eth",
  //   label: "Cost Basis(ETH)",
  // },
  // {
  //   key: "cost_basis_btc",
  //   label: "Cost Basis(BTC)",
  // },
  {
    key: "avg_cost_basis",
    label: "Avg Cost Basis After Order",
  },
  {
    key: "realized_pnl",
    label: "Realized Pnl",
  },
  {
    key: "unrealized_pnl",
    label: "Unrealized Pnl",
  },
  {
    key:'slippage_trade_fees',
    label:'Slippage And Trade Fees'
  },
  {
    key:'cumulative_slippage_trade_fees',
    label:'Cumulative Slippages'
  }
  
  // {
  //   key: "net_leverage_eth",
  //   label: "Net Leverage(ETH)",
    
  // },
  // {
  //   key: "net_leverage_btc",
  //   label: "Net Leverage(BTC)",
    
  // },
  // {
  //   key: "return_value",
  //   label: "Return",
  // },
];


const allOrdersColumns = [
{
  key: "position_id",
  label: "Position",

},
{
  key: "leverage",
  label: "Order Leverage",
 
},
{
  key: "order_type",
  label: "Order Type",
},
{
  key: "asset",
  label: "Asset",
},
{
  key: "processed_time",
  label: "Processed Time",

},

{
  key: "price",
  label: "Price",
}]


function Orders({allOrdersData,setDetailedOrdersTableData,ethOrders, detailedOrdersColumns, detailedOrdersTableData }) {
  
  
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState('ascending');

  const sortData = (data, key, direction = 'descending') => {
    return [...data].sort((a, b) => {
      const first = a[key];
      const second = b[key];
      let cmp = 0;

      if (typeof first === 'number' && typeof second === 'number') {
        cmp = first - second;
      } else if (typeof first === 'string' && typeof second === 'string') {
        cmp = first.localeCompare(second);
      } else if (!isNaN(Date.parse(first)) && !isNaN(Date.parse(second))) {
        cmp = new Date(first) - new Date(second);
      }
      return direction === 'descending' ? -cmp : cmp;
    });
  };

  const handleSortChange = (e) => {
    const key = e.target.value;
    setSortKey(key);
    const sortedData = sortData(detailedOrdersTableData, key, sortDirection);
    setDetailedOrdersTableData(sortedData);
  };

  const handleDirectionChange = (e) => {
    const direction = e.target.value;
    setSortDirection(direction);
    const sortedData = sortData(detailedOrdersTableData, sortKey, direction);
    setDetailedOrdersTableData(sortedData);
  };

  return (
    <div className='w-full h-full'>
      <div className='w-full flex justify-start items-center'>
        <div className='w-full'>
        <p className='text-[#393939]'>Sort By:</p>
        <select
          value={sortKey}
          onChange={handleSortChange}
          className='capitalize border p-2 rounded-lg text-[#393939]'>
          {['Select Option','processed_time', 'leverage', 'order_type', 'price', 'order_size_dollar', 'order_size_token', 'total_position_size', 'market_value', 'portfolio_value', 'cost_basis', 'return_value'].map((item) => (
            <option key={item} value={item}>
              {item.replace(/_/g, " ")}
            </option>
          ))}
        </select>
        <select
          value={sortDirection}
          onChange={handleDirectionChange}
          className='capitalize border p-2 rounded-lg text-[#393939] ml-2'>
          {['Select Option','ascending', 'descending'].map((direction) => (
            <option key={direction} value={direction}>
              {direction}
            </option>
          ))}
        </select>
        </div>
       
       <div className='text-[#393939] font-semibold w-full items-center flex justify-end'>
        Click rows to see Trade History

       {/* <div className='flex items-center'>
       <p>Portfolio Value:</p>
        <input
        id='port_val'
        className='border p-2 rounded-lg text-[#393939] ml-2 w-[100px]'
        />
       </div> */}
       
       
       </div>
      </div>

      <OrdersTabs
          items={['BTC ORDERS','ETH ORDERS','ALL ORDERS']}
          content={[<PerformanceTable
            users={detailedOrdersTableData}
            columns={detailedOrdersColumns}
          />,<PerformanceTable
          users={ethOrders}
          columns={detailedOrdersColumnsForEth}
          
          />,<PerformanceTable
          users={allOrdersData}
          columns={allOrdersColumns}
          
          />]}/>
      
    </div>
  );
}

export default Orders;
