import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { mockDataPositions } from "../../data/mockPositionsData";
import moment from "moment";
import { fetchDashboardData } from "../../functions";

import OrdersComponent from "./OrdersComponent";
import { IoMdRefresh } from "react-icons/io";
import { toast } from "sonner";
import DailyReturnsContext from "../../context/DailyReturnsContext";
import UiTabs from "../tabs/Uitabs";

const Orders = ({ type }) => {
  const {getMinerData,setPositionsData,positionsData} = useContext(DailyReturnsContext)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);


  const tarvisAndTimelessAddress =
    "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t";


  return (
    <Box 
    
    m="20px">
  
        <>
         <div className='w-full flex justify-between items-center pb-[4px]'>
            <p className='text-[#393939] font-bold text-[1.3rem]'>Orders</p>

            <IoMdRefresh 
         onClick={()=>{
          toast.success('Refreshing')
          setPositionsData(null)
          getMinerData()
        }}
        color="#393939" 
        size={25}
        className="cursor-pointer"
        />
          </div>

          <div className="w-full bg-[#D1D5DB] h-[1px]"></div>

          <UiTabs
          items={['Tarvis Miner 1', 'Tarvis Miner 2','Others']}
          content={[<OrdersComponent type="TarvisTwo"
            />,<OrdersComponent type="Tarvis"
            />,<OrdersComponent
            type="Others"
            // statData={mockStatDataOthers}
            // pieChartData={mockPieDataOthers}
            // lineChartData={mockLineDataOthers}
          />]}
          />

        </>

    </Box>
  );
};

export default Orders;
