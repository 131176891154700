import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DailyReturnsContext from '../../context/DailyReturnsContext';
import { useLocation } from 'react-router-dom';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingTop: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function UiTabs({items,content}) {
    const [value, setValue] = React.useState(0);
    const {positionsData,pieChartData, setPieChartDataData,setStatData,
        statData} = React.useContext(DailyReturnsContext)

    const handleChange = (event, newValue) => {
        // simulateCalculation()
        setValue(newValue);
   
        if(value === 0){
            setPieChartDataData([])
        }
        if(value === 1){
          setStatData([])
        }
      };
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
        className='border-b border-[#D1D5DB]'
        value={value} onChange={handleChange} aria-label="basic tabs example">
          {items?.map((item,index)=>(
             <Tab 
             className='!capitalize !text-[#393939]'
             label={item} {...a11yProps(`${index}`)} />
          ))}
        </Tabs>
      </Box>
      {content?.map((item,index)=>(
         <CustomTabPanel value={value} index={index}>
         {item}
       </CustomTabPanel>
      ))}
     
      {/* <CustomTabPanel value={value} index={1}>
        Item Two
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        Item Three
      </CustomTabPanel> */}
    </Box>
  );
}